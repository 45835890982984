
import { getLetterInfoApi } from '@/api/h5.js'

export default {
  data () {
    return {
      letterInfo: {}
    }
  },

  mounted () {
    this.loadData()
  },

  methods: {
    loadData() {
      const houseId = this.$route.query.houseId
      if (houseId) {
        getLetterInfoApi({ houseId }).then(res => {
          const { code, result } = res.data
          if (code === 200) {
            this.letterInfo = result
          }
        })
      }
    }
  }
}
