var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout"
  }, [_c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('cyui-header', {
          scopedSlots: _vm._u([{
            key: "headerLeft",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }, {
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "letter-container"
        }, [_c('p', [_c('br')]), _c('p', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("房屋委托服务合同终止及房屋交接告知函")])]), _c('p', {
          staticClass: "text-left mt-10"
        }, [_vm._v("尊敬的"), _c('u', [_vm._v(_vm._s(_vm.letterInfo.tenantName || ''))]), _vm._v("先生/女士")]), _c('p', {
          staticClass: "text-left"
        }, [_c('span')]), _c('p', {
          staticClass: "text-indent-2 mt-10"
        }, [_c('span', [_vm._v("您好！您与我司于")]), _c('u', [_vm._v(_vm._s(_vm.letterInfo.delegateStartDate || ''))]), _c('span', [_vm._v("签订的《房屋委托服务合同》（以下简称“合同”）即将于")]), _c('u', [_vm._v(_vm._s(_vm.letterInfo.delegateEndDate || ''))]), _c('span', [_vm._v("到期。为确保合同终止及房屋交接事宜的顺利进行，现特此函告如下：")])]), _c('p', [_vm._v("一、合同终止")]), _c('p', {
          staticClass: "text-indent-2"
        }, [_c('strong', [_vm._v("根据合同约定，双方签订的合同将于"), _c('u', [_vm._v(_vm._s(_vm.letterInfo.delegateEndDate || ''))]), _vm._v("正式终止。")]), _vm._v("我司承诺，将在"), _c('u', [_vm._v(_vm._s(_vm.letterInfo.delegateEndDate || ''))]), _vm._v("前将位于无锡市新吴区江溪路28号金科米兰的"), _c('u', [_vm._v(_vm._s(_vm.letterInfo.roomNo || ''))]), _vm._v("（房号）委托服务房屋归还给您。")]), _c('p', [_vm._v("二、房屋交接")]), _c('p', {
          staticClass: "text-indent-2"
        }, [_vm._v("我司将严格按照合同约定，将房屋及其附属家具家电等物品交予您。")]), _c('p', [_vm._v("三、费用结算")]), _c('p', [_vm._v("1.租金回报款：我司给您支付的租金回报款将截止到"), _c('u', [_vm._v(_vm._s(_vm.letterInfo.delegateEndDate || ''))]), _vm._v("；")]), _c('p', [_vm._v("2.相关费用：水费、电费、网络费等相关费用将按照房屋交还时间进行结算，最迟不晚于合同结束期。")]), _c('p', [_vm._v("四、交接事宜协商及联系方式")]), _c('p', {
          staticClass: "text-indent-2"
        }, [_c('strong', [_vm._v("为了确保房屋交接的顺利进行，我们诚挚邀请您于"), _c('u', [_vm._v(_vm._s(_vm.letterInfo.delegateEndDate || ''))]), _vm._v("前至无锡市新吴区江溪路28号金科米兰三楼尔家客服中心与我们共同协商办理房屋交接事宜。")]), _vm._v("我们相信通过双方的共同努力，一定能够妥善处理合同终止及房屋交接的相关事宜。 ")]), _c('p', {
          staticClass: "text-indent-2"
        }, [_vm._v(" 如您对以上事项有任何疑问或需要进一步了解，请随时联系我们。我们的联系方式如下： ")]), _c('p', {
          staticClass: "text-indent-2"
        }, [_c('strong', [_vm._v("联系人："), _c('u', [_vm._v(" " + _vm._s(_vm.letterInfo.contactPerson || '') + " ")]), _vm._v("，电话号码 "), _c('u', [_vm._v("0510-85011313，18106171293 ")]), _vm._v(" 。")])]), _c('p', {
          staticClass: "text-indent-2"
        }, [_vm._v("感谢您一直以来对我们的信任与支持！期待在未来的日子里，我们能够继续为您提供优质的服务。")]), _c('p', {
          staticClass: "text-right mt-20"
        }, [_c('span', [_vm._v("上海尔家商业管理有限公司")])]), _c('p', {
          staticClass: "text-right"
        }, [_c('span', [_vm._v(_vm._s(_vm.letterInfo.noticeDate || ''))])]), _c('p', [_c('br')])])];
      },
      proxy: true
    }])
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };